import { Currency } from '../Currency';
import { UserModel } from '../User';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class TableFeeRow {
  constructor({ summ, fee }: { summ: number, fee: number }) {
    this.sum = summ;
    this.fee = fee;
  }

  sum: number;

  fee: number;

  static fromJson(json: { [key: string]: any }): TableFeeRow {
    return new TableFeeRow({
      summ: json.sum || 0,
      fee: json.fee || 0,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      sum: this.sum,
      fee: this.fee,
    };
  }
}

export class UserSettingsModel {
  constructor({
    id,
    companyName = '',
    idNumber = '',
    email = '',
    country = '',
    city = '',
    addressLine1 = '',
    addressLine2 = '',
    currency = Currency.def,
    taxFee = 1.0,
    useTableFee = false,
    tableFee = [],
    user,
  }: {
    id?: string;
    companyName?: string;
    idNumber?: string;
    email?: string;
    country?: string;
    city?: string;
    addressLine1?: string;
    addressLine2?: string;
    currency?: Currency;
    taxFee?: number;
    useTableFee?: boolean;
    tableFee?: TableFeeRow[];
    user: DocumentReference<UserModel>;
  }) {
    this.id = id;
    this.companyName = companyName;
    this.idNumber = idNumber;
    this.email = email;
    this.country = country;
    this.city = city;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.currency = currency;
    this.taxFee = (useTableFee) ? 0 : taxFee;
    this.useTableFee = useTableFee;
    this.tableFee = (useTableFee) ? tableFee : [];
    this.user = user;
  }

  id?: string;

  companyName: string;

  idNumber: string;

  email: string;

  country: string;

  city: string;

  addressLine1: string;

  addressLine2: string;

  currency: Currency;

  taxFee: number;

  useTableFee: boolean;

  tableFee: TableFeeRow[];

  user: DocumentReference<UserModel>;

  static fromJson(id: string, json: { [key: string]: any }): UserSettingsModel {
    return new UserSettingsModel({
      id: id,
      companyName:
        typeof json?.companyName === 'string' ? json.companyName : '',
      idNumber: typeof json?.idNumber === 'string' ? json.idNumber : '',
      email: typeof json?.email === 'string' ? json.email : '',
      country: typeof json?.country === 'string' ? json.country : '',
      city: typeof json?.city === 'string' ? json.city : '',
      addressLine1:
        typeof json?.addressLine1 === 'string' ? json.addressLine1 : '',
      addressLine2:
        typeof json?.addressLine2 === 'string' ? json.addressLine2 : '',
      currency:
        typeof json?.currency === 'string'
          ? Currency.fromString(json.currency)
          : Currency.def,
      taxFee: (json?.useTableFee === true) ? 0 : (typeof json?.taxFee === 'number' ? json.taxFee : 1.0),
      useTableFee: json?.useTableFee === true,
      tableFee: json?.useTableFee === true && Array.isArray(json?.tableFee) ?
        json.tableFee.map((e) => TableFeeRow.fromJson(e)).sort((a, b) => a.sum - b.sum) : [],
      user: (json.user as DocumentReference).withConverter<UserModel>({
        toFirestore: (doc: UserModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          UserModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      companyName: this.companyName,
      idNumber: this.idNumber,
      email: this.email,
      country: this.country,
      city: this.city,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      currency: this.currency.toString(),
      taxFee: (this.useTableFee) ? 0 : this.taxFee,
      useTableFee: this.useTableFee,
      tableFee: (this.useTableFee) ? this.tableFee.sort((a, b) => a.sum - b.sum).map((e) => e.toJson()) : [],
      user: doc(FirebaseFirestore, this.user.path),
    };
  }

  static parent = collection(
    FirebaseFirestore,
    'usersSettings'
  ).withConverter<UserSettingsModel>({
    toFirestore: (doc: UserSettingsModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      UserSettingsModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<UserSettingsModel>> =>
    getDoc(doc(UserSettingsModel.parent, id));

  ref = (): DocumentReference<UserSettingsModel> => {
    if (typeof this.id === 'string')
      return doc(UserSettingsModel.parent, this.id);
    const docRef = doc(UserSettingsModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (
    transaction?: Transaction
  ): Promise<DocumentSnapshot<UserSettingsModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
