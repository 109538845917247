const createTransactionsData = (
  userCurrency: string,
  userTaxFee: string,
  invoiceId: string,
  invoiceDate: string,
  invoiceNumber: string,
  invoiceAmount: string | null,
  customer: string | null,
  transactionId: string | null,
  transactionDate: string | null,
  transactionSum: string | null,
  transactionExchangeRate: string | null,
) => ({
  userCurrency,
  userTaxFee,
  invoiceId,
  invoiceDate,
  invoiceNumber,
  invoiceAmount,
  customer,
  transactionId,
  transactionDate,
  transactionSum,
  transactionExchangeRate,
});

const transactionsRows = [
  createTransactionsData('GEL', '1', 'KqKVhzyHHq2StCc9jbLf',
    '2022-01-31',
    '2',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    '0',
    '2022-02-04',
    '1000.00',
    '2.9867',
  ),
  createTransactionsData('GEL', '1', '2WUWx9RkulxrmMuPQCcb',
    '2022-02-28',
    '3',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    '0',
    '2022-03-04',
    '1000.00',
    '3.2744',
  ),
  createTransactionsData('GEL', '1', '8yYW320fAfIbkZEpvDau',
    '2022-03-31',
    '4',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    '0',
    '2022-04-04',
    '1000.00',
    '3.0902',
  ),
  createTransactionsData('GEL', '1', '8yYW320fAfIbkZEpvDau',
    '2022-03-31',
    '4',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    '1',
    '2022-04-04',
    '1000.00',
    '3.0902',
  )
] as const;

const transactionsQuery = `
  SELECT
  * FROM
  \`invoicemaker-f5e1d.mini_accountant.transactions_slice_by_range\`(
    PARSE_TIMESTAMP("%Y%m%d",  "20220101", "Asia/Tbilisi"),
    PARSE_TIMESTAMP("%Y%m%d", "20231231", "Asia/Tbilisi"),
    "Asia/Tbilisi"
  );
  `;

export { transactionsRows, transactionsQuery };