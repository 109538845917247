import moment, { Moment } from 'moment-timezone';
import { SubscriptionType } from '../SubscriptionType';
import { UserModel } from '../User';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  Timestamp,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class SubscriptionModel {
  constructor({
    id,
    type = SubscriptionType.def,
    description = '',
    paymentInfo = {},
    validTo,
    user,
  }: {
    id?: string;
    type: SubscriptionType;
    description: string;
    paymentInfo: { [key: string]: any };
    validTo: Moment;
    user: DocumentReference<UserModel>;
  }) {
    this.id = id;
    this.type = type;
    this.description = description;
    this.paymentInfo = paymentInfo;
    this.validTo = validTo;
    this.user = user;
  }

  id?: string;

  type: SubscriptionType;

  description: string;

  paymentInfo: { [key: string]: any };

  validTo: Moment;

  user: DocumentReference<UserModel>;

  static fromJson(id: string, json: { [key: string]: any }): SubscriptionModel {
    return new SubscriptionModel({
      id: id,
      type: SubscriptionType.isValidString(json.type)
        ? SubscriptionType.fromString(json.type)
        : SubscriptionType.def,
      description: typeof json.description === 'string'
        ? json.description
        : '',
      paymentInfo: (typeof json.paymentInfo === 'object' && json.paymentInfo) ? json.paymentInfo : {},
      validTo: moment((json.validTo as Timestamp).toMillis()),
      user: (json.user as DocumentReference).withConverter<UserModel>({
        toFirestore: (doc: UserModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          UserModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      type: this.type.toString(),
      description: this.description,
      paymentInfo: this.paymentInfo,
      validTo: Timestamp.fromMillis(this.validTo.valueOf()),
      user: doc(FirebaseFirestore, this.user.path),
    };
  }

  static parent = collection(FirebaseFirestore, 'subscriptions').withConverter<SubscriptionModel>({
    toFirestore: (doc: SubscriptionModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      SubscriptionModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<SubscriptionModel>> =>
    getDoc(doc(SubscriptionModel.parent, id));

  ref = (): DocumentReference<SubscriptionModel> => {
    if (typeof this.id === 'string')
      return doc(SubscriptionModel.parent, this.id);
    const docRef = doc(SubscriptionModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<SubscriptionModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
