import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class InternalConfigModel {
  constructor({
    maintenanceMode,
    maintenanceMessage = '',
    minAppVersion = 0,
  }: {
    maintenanceMode: boolean;
    maintenanceMessage: string;
    minAppVersion: number;
  }) {
    this.maintenanceMode = maintenanceMode;
    this.maintenanceMessage = maintenanceMessage;
    this.minAppVersion = minAppVersion;
  }

  static id = 'config'

  id = InternalConfigModel.id

  maintenanceMode: boolean;

  maintenanceMessage: string;

  minAppVersion: number;

  static fromJson(json: { [key: string]: any }): InternalConfigModel {
    return new InternalConfigModel({
      maintenanceMode: typeof json?.requests === 'boolean' ? json.maintenanceMode : false,
      maintenanceMessage: typeof json?.maintenanceMessage === 'string' ? json.maintenanceMessage : '',
      minAppVersion:
        typeof json?.minAppVersion === 'number'
          ? json.minAppVersion : 0,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      maintenanceMode: this.maintenanceMode,
      maintenanceMessage: this.maintenanceMessage,
      minAppVersion: this.minAppVersion,
    };
  }

  static parent = collection(FirebaseFirestore, '_internal_').withConverter<InternalConfigModel>({
    toFirestore: (doc: InternalConfigModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      InternalConfigModel.fromJson(snapshot.data()),
  });

  static ref = doc(this.parent, InternalConfigModel.id);

  ref = (): DocumentReference<InternalConfigModel> => {
    return doc(InternalConfigModel.parent, this.id);
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<InternalConfigModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
