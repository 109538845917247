import { Currency } from '../Currency';
import { UserModel } from '../User';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class ServiceModel {
  constructor({
    id,
    name = '',
    price = 0,
    vat = 0,
    currency = new Currency({ name: 'usd' }),
    user,
  }: {
    id?: string;
    name?: string;
    price?: number;
    vat?: number;
    currency?: Currency;
    user: DocumentReference<UserModel>;
  }) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.vat = vat;
    this.currency = currency;
    this.user = user;
  }

  id?: string;

  name: string;

  price: number;

  vat: number;

  currency: Currency;

  user: DocumentReference<UserModel>;

  get vatSum() {
    return this.price * this.vat / 100;
  }

  get priceWithVat() {
    return this.price + this.vatSum;
  }

  static fromJson(id: string, json: { [key: string]: any }): ServiceModel {
    return new ServiceModel({
      id: id,
      name: typeof json?.name === 'string' ? json.name : '',
      price: typeof json?.price === 'number' ? json.price : 0,
      vat: typeof json?.vat === 'number' ? json.vat : 0,
      currency:
        typeof json?.currency === 'string'
          ? Currency.fromString(json.currency)
          : new Currency({ name: 'usd' }),
      user: (json.user as DocumentReference).withConverter<UserModel>({
        toFirestore: (doc: UserModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          UserModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      name: this.name,
      price: this.price,
      vat: this.vat,
      currency: this.currency.toString(),
      user: doc(FirebaseFirestore, this.user.path),
    };
  }

  static parent = collection(FirebaseFirestore, 'services').withConverter<ServiceModel>({
    toFirestore: (doc: ServiceModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      ServiceModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<ServiceModel>> =>
    getDoc(doc(ServiceModel.parent, id));

  ref = (): DocumentReference<ServiceModel> => {
    if (typeof this.id === 'string')
      return doc(ServiceModel.parent, this.id);
    const docRef = doc(ServiceModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<ServiceModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
