import {
  ReactElement,
  cloneElement,
  SyntheticEvent,
  useState,
  useEffect
} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Tab, Tabs } from '@mui/material';
import {
  ApiRoute,
  BigQueryRoute,
  WebhooksRoute,
  ApiKeysRoute,
  ExchangeServerRoute,
} from '../routes';
import FirebaseAuth from '../services/FirebaseAuth';
import { useAuthState } from 'react-firebase-hooks/auth';
import LogoutIcon from '@mui/icons-material/Logout';
import './TopBar.css';

const menuItems = [
  {
    key: "page_api",
    path: "/",
    component: () => <ApiRoute />,
    label: "API",
  },
  {
    key: "page_bigquery",
    path: "/bigquery",
    component: () => <BigQueryRoute />,
    label: "BigQuery",
  },
  {
    key: "page_exchange_server",
    path: "/exchange_server",
    component: () => <ExchangeServerRoute />,
    label: "Exchange Server",
  },
  {
    key: "page_webhooks",
    path: "/webhooks",
    component: () => <WebhooksRoute />,
    label: "Webhooks",
  },
  {
    key: "page_apikeys",
    path: "/apikeys",
    component: () => <ApiKeysRoute />,
    label: "Api Keys",
  }
];

interface Props {
  window?: () => Window;
  children: ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function ScrollableTabsButtonAuto() {
  const location = useLocation();
  const pathIndex = menuItems.findIndex((e) => e.path === location.pathname);

  const [pageIndex, setPageIndex] = useState(pathIndex);

  useEffect(() => {
    const i = menuItems.findIndex((e) => e.path === location.pathname)
    if (typeof menuItems[i]?.path === 'string') {
      setPageIndex(i);
    } else {
      setPageIndex(-1);
    }
  }, [location]);

  const navigate = useNavigate();

  const handleChange = (event: SyntheticEvent, newPageIndex: number) => {
    if (typeof menuItems[newPageIndex]?.path === 'string')
      navigate(menuItems[newPageIndex].path);
    else
      navigate('/404');
  };

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <Tabs
        value={(pageIndex !== -1) ? pageIndex : false}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Header Menu"
      >
        {
          menuItems.map((e, i) => <Tab label={e.label} key={`header_menu_${i}`} />)
        }
      </Tabs>
    </Box>
  );
}

export default function ElevateAppBar() {
  const [user] = useAuthState(FirebaseAuth);

  return (
    <div>
      <ElevationScroll>
        <AppBar>
          <Toolbar>
            <Box sx={{ flexGrow: 0, minWidth: '4vh' }}>
              <img src="./assets/images/settings_white.svg" className="App-logo" alt="logo" />
            </Box>
            <Typography variant="h6" component="div">
              Mini Accountant Developer Portal
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, minWidth: '4vmin', justifyContent: 'flex-end' }}>
              {(user) ? <Typography sx={{ paddingRight: '1vmin' }}>{`${user.email}`}</Typography> : null}
            </Box>
            {(user) ? <Box sx={{ flexGrow: { xs: 1, md: 0 }, display: { xs: 'flex', md: 'unset' }, minWidth: '4vmin', justifyContent: { xs: 'end', md: 'unset' } }}>
              <Button variant="outlined" color="secondary" onClick={() => {
                localStorage.removeItem('authorized');
                FirebaseAuth.signOut();
              }}>
                <LogoutIcon />
              </Button>
            </Box> : null}
          </Toolbar>
          {(user) ? <ScrollableTabsButtonAuto /> : null}
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Toolbar />
    </div>
  );
}

export { menuItems };