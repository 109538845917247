import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import './BigQueryRoute.css';
import { invoicesRows, invoicesQuery } from '../utils/exampleInvoices';
import { positionsRows, positionsQuery } from '../utils/examplePositions';
import { transactionsRows, transactionsQuery } from '../utils/exampleTransactions';

function BigQueryRoute() {
  return (
    <div className='BigQueryRoute'>
      <Typography>You can use special BigQuery table functions and SQL Queries to get your's data.</Typography>
      <Typography>First of all, you will need a Google Account with your email address (the email must match that in the application),
        and also create your own <Link href="https://console.cloud.google.com/welcome">Google Cloud Platform (GCP)</Link> project.
        You can do this for free (see <Link href="https://miniaccountant.app/storage/manual.pdf#page=8">Integration with Google Looker</Link>).
        You must have the <Link href="https://cloud.google.com/bigquery/docs/access-control#bq-permissions">bigquery.jobs.create</Link> capability in your GCP project.
        You will independently bear the costs of running tasks and working with data in accordance with the GCP tariffs.
        However, you will have some free limit.
        To run requests to the dataset `invoicemaker-f5e1d.mini_accountant`,
        you need to authenticate with Google using <Link href="https://developers.google.com/identity/protocols/oauth2">OAuth2</Link> before running requests.
      </Typography>
      <br />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="apikeys-content"
          id="apikeys-header"
        >
          <Typography fontWeight='bold'>A slice of the invoices by date range</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextareaAutosize value={invoicesQuery} style={{ width: '100vmin', maxWidth: '100%' }}></TextareaAutosize>
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell key={`invoices_table_header_Row`}>Row</TableCell>
                  {
                    Object.keys(invoicesRows[0]).map((e) =>
                      <TableCell align="right" key={`invoices_table_header_${e}`}>{e}</TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {invoicesRows.map((row, ind) =>
                  <TableRow
                    key={ind}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" key={`invoices_table_cell_Row_${ind}`}>{ind}</TableCell>
                    {
                      Object.keys(invoicesRows[0]).map((e) =>
                        <TableCell align="right" key={`invoices_table_cell_${e}_${ind}`}>{
                          row[e as keyof typeof invoicesRows[0]]
                        }</TableCell>
                      )
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="apikeys-content"
          id="apikeys-header"
        >
          <Typography fontWeight='bold'>A slice of the positions by date range</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextareaAutosize value={positionsQuery} style={{ width: '100vmin', maxWidth: '100%' }}></TextareaAutosize>
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell key={`positions_table_header_Row`}>Row</TableCell>
                  {
                    Object.keys(positionsRows[0]).map((e) =>
                      <TableCell align="right" key={`positions_table_header_${e}`}>{e}</TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {positionsRows.map((row, ind) =>
                  <TableRow
                    key={ind}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" key={`positions_table_cell_Row_${ind}`}>{ind}</TableCell>
                    {
                      Object.keys(positionsRows[0]).map((e) =>
                        <TableCell align="right" key={`positions_table_cell_${e}_${ind}`}>{
                          row[e as keyof typeof positionsRows[0]]
                        }</TableCell>
                      )
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="apikeys-content"
          id="apikeys-header"
        >
          <Typography fontWeight='bold'>A slice of the transactions by date range</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextareaAutosize value={transactionsQuery} style={{ width: '100vmin', maxWidth: '100%' }}></TextareaAutosize>
          <br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell key={`transactions_table_header_Row`}>Row</TableCell>
                  {
                    Object.keys(transactionsRows[0]).map((e) =>
                      <TableCell align="right" key={`transactions_table_header_${e}`}>{e}</TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionsRows.map((row, ind) =>
                  <TableRow
                    key={ind}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" key={`transactions_table_cell_Row_${ind}`}>{ind}</TableCell>
                    {
                      Object.keys(transactionsRows[0]).map((e) =>
                        <TableCell align="right" key={`transactions_table_cell_${e}_${ind}`}>{
                          row[e as keyof typeof transactionsRows[0]]
                        }</TableCell>
                      )
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <br />
    </div>
  );
}

export default BigQueryRoute;