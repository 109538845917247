import { HttpsCallableResult } from "firebase/functions";
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import FirebaseFunctions from '../services/FirebaseFunctions';

const methodName = 'v1_apitoken_delete' as const;

export type DeleteApiTokenResult = HttpsCallableResult<{ id: string }>
export type DeleteApiTokenData = {
  id: string,
};

type DeleteApiTokenDataRun = (data: {
  action: typeof methodName,
  data: DeleteApiTokenData
}) => Promise<DeleteApiTokenResult>;

export type DeleteApiToken = Readonly<[
  run: (data: DeleteApiTokenData) => Promise<DeleteApiTokenResult>,
  progress: boolean,
  err?: Error
]>;

export const useDeleteApiToken = (): DeleteApiToken => {
  const orig = useHttpsCallable(FirebaseFunctions, 'app');
  return [
    (data: DeleteApiTokenData) => (orig[0] as DeleteApiTokenDataRun)({
      action: methodName,
      data,
    }),
    orig[1],
    orig[2]
  ];
};
