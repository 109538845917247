import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from '@mui/material';
import {
  doc,
  query,
  where,
} from 'firebase/firestore';
import './ApiKeysRoute.css';
import { ApiTokenModel, fullScope } from '../models/database/ApiToken'
import { useCreateApiToken } from '../methods/createApiToken';
import { useDeleteApiToken } from '../methods/deleteApiToken';
import moment from 'moment-timezone';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FirebaseAuth from '../services/FirebaseAuth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UserModel } from '../models/database/User';
import Loader from '../components/Loader';

const scopeArr = fullScope;

const getScopeData = (apiToken: ApiTokenModel) => {
  try {
    if (Array.isArray(apiToken.scope) && apiToken.scope.length > 0) {
      const { scope } = apiToken;
      if (Array.isArray(scope) && scope.length > 0) return <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListItem disablePadding key={`tokens_table_row_${apiToken.id}_cell_scope_list_first`}>
            <ListItemText primary={scope[0]} key={`tokens_table_row_${apiToken.id}_cell_scope_list_item_first`} />
          </ListItem>
        </AccordionSummary>
        <AccordionDetails>
          {
            scope.splice(1).map((e, i) =>
              <ListItem disablePadding key={`tokens_table_row_${apiToken.id}_cell_scope_list_${i}`}>
                <ListItemText primary={e} key={`tokens_table_row_${apiToken.id}_cell_scope_list_item_${i}`} />
              </ListItem>)
          }
        </AccordionDetails>
      </Accordion>;
    }
  } catch (err) {
    console.error(err);
  }
  return <ListItem disablePadding key={`tokens_table_row_${apiToken.id}_cell_scope_list_none`}>
    <ListItemText primary={"-"} key={`tokens_table_row_${apiToken.id}_cell_scope_list_item_none`} />
  </ListItem>;
}

const getRequests = (apiToken: ApiTokenModel) => {
  const dt = moment();
  let req = 0;
  if (
    apiToken.requests &&
    apiToken.requests[dt.format('YYYY')] &&
    apiToken.requests[dt.format('YYYY')][dt.format('MM')])
    req = apiToken.requests[dt.format('YYYY')][dt.format('MM')];
  return <div>{req}/{apiToken.limit || 0}</div>;
}

const getLastUse = (apiToken: ApiTokenModel) => {
  if (moment.isMoment(apiToken.lastRequest)) return <div>{apiToken.lastRequest.format('YYYY-MM-DD')}</div>;
  return <div>-</div>;
}

function ApiKeysRoute() {
  const apiTokensQuery = query(
    ApiTokenModel.parent,
    where(
      'user',
      '==',
      doc(UserModel.parent, `${FirebaseAuth.currentUser?.uid}`)
    )
  );
  const [apiTokens] = useCollectionData(apiTokensQuery);
  const createApiToken = useCreateApiToken();
  const deleteApiToken = useDeleteApiToken();
  const [showMsg, setShowMsg] = useState({} as {
    type: 'success' | 'warning' | 'info' | 'error';
    message: string;
    isShown: boolean;
  })

  return (!apiTokens) ? <Loader></Loader> : (
    <div className='ApiKeysRoute'>
      {(apiTokens?.length === 0) ?
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <div />
          <Button sx={{ marginLeft: "auto" }} variant="outlined" color="primary" onClick={() => {
            createApiToken[0]({ scope: scopeArr }).then(() => {
              setShowMsg({ type: 'success', message: 'Created!', isShown: true });
            }).catch((err: Error) => {
              setShowMsg({ type: 'error', message: err.message || 'Unknown error', isShown: true });
            });
          }} disabled={createApiToken[1] || deleteApiToken[1]}>
            <AddCircleOutlineOutlinedIcon />
          </Button> </Toolbar> :
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">SCOPE</TableCell>
                <TableCell align="center">REQUESTS (MONTH)</TableCell>
                <TableCell align="center">LAST USE</TableCell>
                <TableCell align="center">COPY</TableCell>
                <TableCell align="center">REFRESH</TableCell>
                <TableCell align="center">REMOVE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                apiTokens?.map((e) =>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`tokens_table_row_${e.id}`}>
                    <TableCell align="center" key={`tokens_table_row_${e.id}_cell_id`}>{e.id}</TableCell>
                    <TableCell align="center" key={`tokens_table_row_${e.id}_cell_scope`}>{getScopeData(e)}</TableCell>
                    <TableCell align="center" key={`tokens_table_row_${e.id}_cell_requests`}>{getRequests(e)}</TableCell>
                    <TableCell align="center" key={`tokens_table_row_${e.id}_cell_lastuse`}>{getLastUse(e)}</TableCell>
                    <TableCell align="center" key={`tokens_table_row_${e.id}_cell_copy`}>
                      <Button variant="outlined" color="primary" onClick={async () => {
                        await navigator.clipboard.writeText(e.token || '').then(() => {
                          setShowMsg({ type: 'success', message: 'Copied!', isShown: true });
                        }).catch((err: Error) => {
                          setShowMsg({ type: 'error', message: err.message || 'Unknown error', isShown: true });
                        });
                      }} disabled={createApiToken[1] || deleteApiToken[1]} key={`tokens_table_row_${e.id}_cell_copy_button`}>
                        <ContentCopyOutlinedIcon key={`tokens_table_row_${e.id}_copy_button_icon`} />
                      </Button></TableCell>
                    <TableCell align="center" key={`tokens_table_row_${e.id}_update`}>
                      <Button variant="outlined" color="primary" onClick={() => {
                        createApiToken[0]({ id: e.id as string, scope: scopeArr }).then(() => {
                          if (!createApiToken[2])
                            setShowMsg({ type: 'success', message: 'Updated!', isShown: true });
                          else
                            setShowMsg({ type: 'error', message: createApiToken[2].message || 'Unknown error', isShown: true });
                        }).catch((err: Error) => {
                          setShowMsg({ type: 'error', message: err.message || 'Unknown error', isShown: true });
                        });
                      }} disabled={createApiToken[1] || deleteApiToken[1]} key={`tokens_table_row_${e.id}_update_button`}>
                        <UpdateOutlinedIcon key={`tokens_table_row_${e.id}_update_button_icon`} />
                      </Button></TableCell>
                    <TableCell align="center" key={`tokens_table_row_${e.id}_delete`}>
                      <Button variant="outlined" color="primary" onClick={() => {
                        deleteApiToken[0]({ id: e.id as string }).then(() => {
                          if (!deleteApiToken[2])
                            setShowMsg({ type: 'success', message: 'Removed!', isShown: true });
                          else
                            setShowMsg({ type: 'error', message: deleteApiToken[2].message || 'Unknown error', isShown: true });
                        }).catch((err: Error) => {
                          setShowMsg({ type: 'error', message: err.message || 'Unknown error', isShown: true });
                        });
                      }} disabled={createApiToken[1] || deleteApiToken[1]} key={`tokens_table_row_${e.id}_delete_button`}>
                        <DeleteOutlineOutlinedIcon key={`tokens_table_row_${e.id}_delete_button_icon`} />
                      </Button></TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      }
      <Snackbar open={showMsg.isShown} autoHideDuration={6000} onClose={() => {
        setShowMsg({ ...showMsg, isShown: false });
      }} sx={{ width: '100%', padding: '3vmin', justifyContent: 'end' }}>
        {
          <Alert onClose={() => {
            setShowMsg({ ...showMsg, isShown: false });
          }} sx={{ marginRight: '3vmin' }} severity={showMsg.type}>{showMsg.message}</Alert>
        }
      </Snackbar>
    </div>
  );
}

export default ApiKeysRoute;