import { PaymentMethod } from '../PaymentMethod';
import { UserModel } from '../User';
import { CustomerModel } from '../Customer';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  Query,
  collection,
  doc,
  getDoc,
  setDoc,
  where,
  query,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class PaymentSettingsModel {
  constructor({
    id,
    due = '',
    method = PaymentMethod.def,
    bankBenefeciaryName = '',
    bankAccount = '',
    bankName = '',
    bankSwiftCode = '',
    intermediaryBankName = '',
    intermediaryBankSwiftCode = '',
    intermediaryBankAba = '',
    achBenefeciaryName = '',
    achRoutingNumber = '',
    achAccountNumber = '',
    achType = '',
    achBankAddress = '',
    payoneerEmail = '',
    payoneerAccount = '',
    paypalEmail = '',
    paypalAccount = '',
    paypalMobile = '',
    cardNumber = '',
    cardOwner = '',
    user,
  }: {
    id?: string;
    due?: string;
    method?: PaymentMethod;
    bankBenefeciaryName?: string;
    bankAccount?: string;
    bankName?: string;
    bankSwiftCode?: string;
    intermediaryBankName?: string;
    intermediaryBankSwiftCode?: string;
    intermediaryBankAba?: string;
    achBenefeciaryName?: string;
    achRoutingNumber?: string;
    achAccountNumber?: string;
    achType?: string;
    achBankAddress?: string;
    payoneerEmail?: string;
    payoneerAccount?: string;
    paypalEmail?: string;
    paypalAccount?: string;
    paypalMobile?: string;
    cardNumber?: string;
    cardOwner?: string;
    user: DocumentReference<UserModel>;
  }) {
    this.id = id;
    this.due = due;
    this.method = method;
    this.bankBenefeciaryName = bankBenefeciaryName;
    this.bankAccount = bankAccount;
    this.bankName = bankName;
    this.bankSwiftCode = bankSwiftCode;
    this.intermediaryBankName = intermediaryBankName;
    this.intermediaryBankSwiftCode = intermediaryBankSwiftCode;
    this.intermediaryBankAba = intermediaryBankAba;
    this.achBenefeciaryName = achBenefeciaryName;
    this.achRoutingNumber = achRoutingNumber;
    this.achAccountNumber = achAccountNumber;
    this.achType = achType;
    this.achBankAddress = achBankAddress;
    this.payoneerEmail = payoneerEmail;
    this.payoneerAccount = payoneerAccount;
    this.paypalEmail = paypalEmail;
    this.paypalAccount = paypalAccount;
    this.paypalMobile = paypalMobile;
    this.cardNumber = cardNumber;
    this.cardOwner = cardOwner;
    this.user = user;
  }

  id?: string;

  due: string;

  method: PaymentMethod;

  bankBenefeciaryName: string;

  bankAccount: string;

  bankName: string;

  bankSwiftCode: string;

  intermediaryBankName: string;

  intermediaryBankSwiftCode: string;

  intermediaryBankAba: string;

  achBenefeciaryName: string;

  achRoutingNumber: string;

  achAccountNumber: string;

  achType: string;

  achBankAddress: string;

  payoneerEmail: string;

  payoneerAccount: string;

  paypalEmail: string;

  paypalAccount: string;

  paypalMobile: string;

  cardNumber: string;

  cardOwner: string;

  user: DocumentReference<UserModel>;

  static fromJson(
    id: string,
    json: { [key: string]: any }
  ): PaymentSettingsModel {
    return new PaymentSettingsModel({
      id: id,
      due: typeof json?.due === 'string' ? json.due : '',
      method:
        typeof json?.method === 'string'
          ? PaymentMethod.fromString(json.method)
          : PaymentMethod.def,
      bankBenefeciaryName:
        typeof json?.bankBenefeciaryName === 'string'
          ? json.bankBenefeciaryName
          : '',
      bankAccount:
        typeof json?.bankAccount === 'string' ? json.bankAccount : '',
      bankName: typeof json?.bankName === 'string' ? json.bankName : '',
      bankSwiftCode:
        typeof json?.bankSwiftCode === 'string' ? json.bankSwiftCode : '',
      intermediaryBankName:
        typeof json?.intermediaryBankName === 'string'
          ? json.intermediaryBankName
          : '',
      intermediaryBankSwiftCode:
        typeof json?.intermediaryBankSwiftCode === 'string'
          ? json.intermediaryBankSwiftCode
          : '',
      intermediaryBankAba:
        typeof json?.intermediaryBankAba === 'string'
          ? json.intermediaryBankAba
          : '',
      achBenefeciaryName:
        typeof json?.achBenefeciaryName === 'string'
          ? json.achBenefeciaryName
          : '',
      achRoutingNumber:
        typeof json?.achRoutingNumber === 'string' ? json.achRoutingNumber : '',
      achAccountNumber:
        typeof json?.achAccountNumber === 'string' ? json.achAccountNumber : '',
      achType: typeof json?.achType === 'string' ? json.achType : '',
      achBankAddress:
        typeof json?.achBankAddress === 'string' ? json.achBankAddress : '',
      payoneerEmail:
        typeof json?.payoneerEmail === 'string' ? json.payoneerEmail : '',
      payoneerAccount:
        typeof json?.payoneerAccount === 'string' ? json.payoneerAccount : '',
      paypalEmail:
        typeof json?.paypalEmail === 'string' ? json.paypalEmail : '',
      paypalAccount:
        typeof json?.paypalAccount === 'string' ? json.paypalAccount : '',
      paypalMobile:
        typeof json?.paypalMobile === 'string' ? json.paypalMobile : '',
      cardNumber: typeof json?.cardNumber === 'string' ? json.cardNumber : '',
      cardOwner: typeof json?.cardOwner === 'string' ? json.cardOwner : '',
      user: (json.user as DocumentReference).withConverter<UserModel>({
        toFirestore: (doc: UserModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          UserModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      due: this.due,
      method: this.method.toString(),
      bankBenefeciaryName: this.bankBenefeciaryName,
      bankAccount: this.bankAccount,
      bankName: this.bankName,
      bankSwiftCode: this.bankSwiftCode,
      intermediaryBankName: this.intermediaryBankName,
      intermediaryBankSwiftCode: this.intermediaryBankSwiftCode,
      intermediaryBankAba: this.intermediaryBankAba,
      achBenefeciaryName: this.achBenefeciaryName,
      achRoutingNumber: this.achRoutingNumber,
      achAccountNumber: this.achAccountNumber,
      achType: this.achType,
      achBankAddress: this.achBankAddress,
      payoneerEmail: this.payoneerEmail,
      payoneerAccount: this.payoneerAccount,
      paypalEmail: this.paypalEmail,
      paypalAccount: this.paypalAccount,
      paypalMobile: this.paypalMobile,
      cardNumber: this.cardNumber,
      cardOwner: this.cardOwner,
      user: doc(FirebaseFirestore, this.user.path),
    };
  }

  static parent = collection(FirebaseFirestore,
    'paymentsSettings'
  ).withConverter<PaymentSettingsModel>({
    toFirestore: (doc: PaymentSettingsModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      PaymentSettingsModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (
    id: string
  ): Promise<DocumentSnapshot<PaymentSettingsModel>> =>
    getDoc(doc(PaymentSettingsModel.parent, id));

  ref = (): DocumentReference<PaymentSettingsModel> => {
    if (typeof this.id === 'string')
      return doc(PaymentSettingsModel.parent, this.id);
    const docRef = doc(PaymentSettingsModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (
    transaction?: Transaction
  ): Promise<DocumentSnapshot<PaymentSettingsModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);

  child = {
    getCustomers: (): Query<CustomerModel> =>
      query(CustomerModel.parent, where('paymentSettings', '==', this.ref())),
  };
}
