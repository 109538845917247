import { Outlet, Route, Routes } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { NoMatchRoute } from '../routes';
import FirebaseAuth from '../services/FirebaseAuth';
import Loader from '../components/Loader';
import TopBar, { menuItems } from '../components/TopBar';
import SignIn from '../components/SignIn';
import { Link, Paper, Typography } from '@mui/material';
import './BaseScreen.css';

export default function BaseScreen() {
  const [user, loading] = useAuthState(FirebaseAuth);

  return (
    <div className="App">
      <TopBar />
      {
        (loading) ? <Loader /> :
          (user) ?
            <Routes>
              <Route path="/" element={<Outlet />} key="page_main">
                {
                  menuItems.map((e, i) => {
                    if (i === 0) {
                      return <Route index element={e.component()} key={e.key} />;
                    }
                    return <Route path={e.path.substring(1)} element={e.component()} key={e.key} />;
                  })
                }
                <Route path="*" element={<NoMatchRoute />} key="page_notfound" />
              </Route>
            </Routes>
            : <Routes>
              <Route path="/" element={<SignIn />} key="page_signin"></Route>
              <Route path="*" element={<SignIn />} key="page_signin"></Route>
            </Routes>
      }
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <Typography align='center'><Link href="https://dudko.dev" underline='none'>2022-{new Date().getFullYear()}©by siarhei dudko</Link></Typography>
      </Paper>
    </div >
  );
}