import { Box } from '@mui/material';
import SwaggerUI from 'swagger-ui-react';
import "swagger-ui-react/swagger-ui.css"
import './ApiRoute.css';

function ApiRoute() {
  return (
    <div className='ApiRoute'>
      <SwaggerUI url='openapi.yml' persistAuthorization={true} defaultModelExpandDepth={-1} docExpansion='none' />
      <Box sx={{ marginBottom: '10vmin' }} />
    </div>
  );
}

export default ApiRoute;