import { HttpsCallableResult } from "firebase/functions";
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import FirebaseFunctions from '../services/FirebaseFunctions';

const methodName = 'v1_apitoken_create' as const;

export type CreateApiTokenResult = HttpsCallableResult<{ id: string }>
export type CreateApiTokenData = {
  id?: string,
  scope?: string[]
};

type CreateApiTokenDataRun = (data: {
  action: typeof methodName,
  data: CreateApiTokenData
}) => Promise<CreateApiTokenResult>;

export type CreateApiToken = Readonly<[
  run: (data: CreateApiTokenData) => Promise<CreateApiTokenResult>,
  progress: boolean,
  err?: Error
]>;

export const useCreateApiToken = (): CreateApiToken => {
  const orig = useHttpsCallable(FirebaseFunctions, 'app');
  return [
    (data: CreateApiTokenData) => (orig[0] as CreateApiTokenDataRun)({
      action: methodName,
      data,
    }),
    orig[1],
    orig[2]
  ];
};
