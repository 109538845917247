const applicationThemes = (
  ['baseDarkTheme',
    'modernDarkTheme',
    'baseLightTheme',
    'modernLightTheme'] as const
).map((e) => e);
export type ApplicationThemes = typeof applicationThemes[0];

export class ApplicationTheme {
  constructor({ name }: { name: ApplicationThemes }) {
    this.name = name;
  }

  name: ApplicationThemes;

  static def: ApplicationTheme = new ApplicationTheme({ name: 'baseDarkTheme' });

  static fromString(str: string): ApplicationTheme {
    const name = applicationThemes.find((e) => e === str.toLowerCase());
    if (name) {
      return new ApplicationTheme({ name });
    }
    throw new Error('Invalid type of the application theme.');
  }

  static isValidString(str?: string): Boolean {
    const name = applicationThemes.find((e) => e === str?.toLowerCase());
    return !!name;
  }

  toString(): string {
    return this.name.toUpperCase();
  }
}
