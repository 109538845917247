const paymentMethods = (
  ['swift', 'ach', 'payoneer', 'paypal', 'card'] as const
).map((e) => e);
export type PaymentMethods = typeof paymentMethods[0];

export class PaymentMethod {
  constructor({ name }: { name: PaymentMethods }) {
    this.name = name;
  }

  name: PaymentMethods;

  static def: PaymentMethod = new PaymentMethod({ name: 'swift' });

  static fromString(str: string): PaymentMethod {
    const name = paymentMethods.find((e) => e === str.toLowerCase());
    if (name) {
      return new PaymentMethod({ name });
    }
    throw new Error('Invalid type of the payment method.');
  }

  static isValidString(str?: string): Boolean {
    const name = paymentMethods.find((e) => e === str?.toLowerCase());
    return !!name;
  }

  toString(): string {
    return this.name.toUpperCase();
  }
}
