import { UserSettingsModel } from '../UserSettings';
import { PaymentSettingsModel } from '../PaymentSettings';
import { ServiceModel } from '../Service';
import { CustomerModel } from '../Customer';
import { InvoiceModel } from '../Invoice';
import { ExpenseModel } from '../Expense';
import { ApiTokenModel } from '../ApiToken';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  Query,
  collection,
  doc,
  getDoc,
  where,
  query,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';
import { ApplicationTheme } from '../ApplicationTheme';
import { SubscriptionModel } from '../Subscription';

export class UserModel {
  constructor({
    id,
    email,
    invoiceMtx,
    fcmToken = '',
    theme = ApplicationTheme.def,
    customExchangeServer = '',
  }: {
    id?: string;
    email: string;
    invoiceMtx: number;
    fcmToken?: string;
    theme: ApplicationTheme;
    customExchangeServer?: string;
  }) {
    this.id = id;
    this.email = email;
    this.invoiceMtx = invoiceMtx;
    this.fcmToken = fcmToken;
    this.theme = theme;
    this.customExchangeServer = customExchangeServer;
  }

  id?: string;

  email: string;

  invoiceMtx: number;

  fcmToken?: string;

  theme: ApplicationTheme;

  customExchangeServer?: string;

  static fromJson(id: string, json: { [key: string]: any }): UserModel {
    return new UserModel({
      id: id,
      email: typeof json?.email === 'string' ? json.email : '',
      invoiceMtx: typeof json?.invoiceMtx === 'number' ? json.invoiceMtx : 0,
      fcmToken: typeof json?.fcmToken === 'string' ? json.fcmToken : '',
      theme: ApplicationTheme.isValidString(json?.theme) ?
        ApplicationTheme.fromString(json?.theme) : ApplicationTheme.def,
      customExchangeServer: typeof json?.customExchangeServer === 'string' ? json.customExchangeServer : '',
    });
  }

  toJson(): { [key: string]: any } {
    return {
      email: this.email,
      invoiceMtx: this.invoiceMtx,
      fcmToken: this.fcmToken,
      theme: this.theme.toString(),
      customExchangeServer: this.customExchangeServer,
    };
  }

  static parent = collection(FirebaseFirestore, 'users').withConverter<UserModel>({
    toFirestore: (doc: UserModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      UserModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<UserModel>> =>
    getDoc(doc(UserModel.parent, id));

  ref = (): DocumentReference<UserModel> => {
    if (typeof this.id === 'string')
      return doc(UserModel.parent, this.id);
    const docRef = doc(UserModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<UserModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  child = {
    getUsersSettings: (): Query<UserSettingsModel> =>
      query(UserSettingsModel.parent, where('user', '==', this.ref())),
    getPaymentsSettings: (): Query<PaymentSettingsModel> =>
      query(PaymentSettingsModel.parent, where('user', '==', this.ref())),
    getServices: (): Query<ServiceModel> =>
      query(ServiceModel.parent, where('user', '==', this.ref())),
    getCustomers: (): Query<CustomerModel> =>
      query(CustomerModel.parent, where('user', '==', this.ref())),
    getInvoices: (): Query<InvoiceModel> =>
      query(InvoiceModel.parent, where('user', '==', this.ref())),
    getExpenses: (): Query<ExpenseModel> =>
      query(ExpenseModel.parent, where('user', '==', this.ref())),
    getApiTokens: (): Query<ApiTokenModel> =>
      query(ApiTokenModel.parent, where('user', '==', this.ref())),
    getSubscriptions: (): Query<SubscriptionModel> =>
      query(SubscriptionModel.parent, where('user', '==', this.ref())),
  };
}
