import moment, { Moment } from 'moment-timezone';
import { Currency } from '../Currency';
import { UserModel } from '../User';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  Timestamp,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class ExpenseModel {
  constructor({
    id,
    date,
    name = '',
    description = '',
    currency = new Currency({ name: 'usd' }),
    sum = 0,
    exchangeRate = 1,
    vat = 0,
    user,
  }: {
    id?: string;
    date: Moment;
    name?: string;
    description?: string;
    currency?: Currency;
    sum?: number;
    exchangeRate?: number;
    vat?: number;
    user: DocumentReference<UserModel>;
  }) {
    this.id = id;
    this.date = date;
    this.name = name;
    this.description = description;
    this.currency = currency;
    this.sum = sum;
    this.exchangeRate = exchangeRate;
    this.vat = vat;
    this.user = user;
  }

  id?: string;

  date: Moment;

  name: string;

  description: string;

  currency: Currency;

  sum: number;

  exchangeRate: number;

  vat: number;

  user: DocumentReference<UserModel>;

  static fromJson(id: string, json: { [key: string]: any }): ExpenseModel {
    return new ExpenseModel({
      id: id,
      date: moment((json.date as Timestamp).toMillis()),
      name: typeof json.name === 'string'
        ? json.name
        : '',
      description: typeof json.description === 'string'
        ? json.description
        : '',
      currency:
        Currency.isValidString(json.currency)
          ? Currency.fromString(json.currency)
          : new Currency({ name: 'usd' }),
      sum: Number.isFinite(json.sum) ? json.sum : 0,
      exchangeRate: Number.isFinite(json.exchangeRate) ? json.exchangeRate : 1,
      vat: Number.isFinite(json.vat) ? json.vat : 0,
      user: (json.user as DocumentReference).withConverter<UserModel>({
        toFirestore: (doc: UserModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          UserModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      date: Timestamp.fromMillis(this.date.valueOf()),
      name: this.name,
      description: this.description,
      currency: this.currency.toString(),
      sum: this.sum,
      exchangeRate: this.exchangeRate,
      vat: this.vat,
      user: doc(FirebaseFirestore, this.user.path),
    };
  }

  static parent = collection(FirebaseFirestore, 'expenses').withConverter<ExpenseModel>({
    toFirestore: (doc: ExpenseModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      ExpenseModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<ExpenseModel>> =>
    getDoc(doc(ExpenseModel.parent, id));

  ref = (): DocumentReference<ExpenseModel> => {
    if (typeof this.id === 'string')
      return doc(ExpenseModel.parent, this.id);
    const docRef = doc(ExpenseModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<ExpenseModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
