const subscriptionTypes = (
  ['basic'] as const
).map((e) => e);
export type SubscriptionTypes = typeof subscriptionTypes[0];

export class SubscriptionType {
  constructor({ name }: { name: SubscriptionTypes }) {
    this.name = name;
  }

  name: SubscriptionTypes;

  static def: SubscriptionType = new SubscriptionType({ name: 'basic' });

  static fromString(str: string): SubscriptionType {
    const name = subscriptionTypes.find((e) => e === str.toLowerCase());
    if (name) {
      return new SubscriptionType({ name });
    }
    throw new Error('Invalid type of the payment method.');
  }

  static isValidString(str?: string): Boolean {
    const name = subscriptionTypes.find((e) => e === str?.toLowerCase());
    return !!name;
  }

  toString(): string {
    return this.name.toUpperCase();
  }
}
