const createPositionsData = (
  userCurrency: string,
  userTaxFee: string,
  invoiceId: string,
  invoiceDate: string,
  invoiceNumber: string,
  invoiceAmount: string | null,
  customer: string | null,
  positionId: string | null,
  positionName: string | null,
  positionPrice: string | null,
  positionCurrency: string | null,
) => ({
  userCurrency,
  userTaxFee,
  invoiceId,
  invoiceDate,
  invoiceNumber,
  invoiceAmount,
  customer,
  positionId,
  positionName,
  positionPrice,
  positionCurrency,
});

const positionsRows = [
  createPositionsData('GEL', '1', 'KqKVhzyHHq2StCc9jbLf',
    '2022-01-31',
    '2',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    'MtvrSGXZpV6hw5ohXY25',
    'Provision of software development services under the contract dated 01.01.2022',
    '1000.00',
    null,),
  createPositionsData('GEL', '1', '2WUWx9RkulxrmMuPQCcb',
    '2022-02-28',
    '3',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    'MtvrSGXZpV6hw5ohXY25',
    'Provision of software development services under the contract dated 01.01.2022',
    '1000.00',
    null,),
  createPositionsData('GEL', '1', '8yYW320fAfIbkZEpvDau',
    '2022-03-31',
    '4',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    'MtvrSGXZpV6hw5ohXY25',
    'Provision of software development services under the contract dated 01.01.2022',
    '1000.00',
    'USD',),
  createPositionsData('GEL', '1', '8yYW320fAfIbkZEpvDau',
    '2022-03-31',
    '4',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    'MtvrSGXZpV6hw5ohXY25',
    'Provision of software development services under the contract dated 01.01.2022',
    '1000.00',
    'USD',)
] as const;

const positionsQuery = `
  SELECT
  * FROM
  \`invoicemaker-f5e1d.mini_accountant.positions_slice_by_range\`(
    PARSE_TIMESTAMP("%Y%m%d",  "20220101", "Asia/Tbilisi"),
    PARSE_TIMESTAMP("%Y%m%d", "20231231", "Asia/Tbilisi"),
    "Asia/Tbilisi"
  );
  `;

export { positionsRows, positionsQuery };