import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItemText,
  Snackbar,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import {
  doc, updateDoc,
} from 'firebase/firestore';
import './ExchangeServerRoute.css';
import { UserModel } from '../models/database/User'
import FirebaseAuth from '../services/FirebaseAuth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useState } from 'react';
import Loader from '../components/Loader';


function ExchangeServerRoute() {
  const userRef = doc(UserModel.parent, FirebaseAuth.currentUser?.uid);
  const [user] = useDocumentData(userRef);
  const [exchangeServerUrl, setExchangeServerUrl] = useState({
    server: '',
    isChanged: false,
  });
  const baseUrl = 'https://api.miniaccountant.app/v1/exchange_rate';
  const [showMsg, setShowMsg] = useState({} as {
    type: 'success' | 'warning' | 'info' | 'error';
    message: string;
    isShown: boolean;
  });

  return (!user) ? <Loader></Loader> : (
    <div className='ExchangeServerRoute'>
      <Typography>Here you can set up your own server to receive currency rates in the application.</Typography>
      <Typography>Status: <b>{(user?.customExchangeServer || '').length > 0 ? 'enabled' : 'disabled'}</b></Typography>
      <Typography sx={{ marginTop: '3vmin' }}>You can check the operation of the server by specifying the following link, where you need to replace the API_KEY with your token from the API KEYS page (attention, each request to the test server uses your API request limit). Pay attention to the debug=true parameter. With it, the server returns the exchange rate of 1 and a service message. If you do not specify it, the server will return the real rate.</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>{'https://api.miniaccountant.app/v1/exchange_rate/{{ date }}/{{ from }}/{{ to }}?debug=true&token=API_KEY'}</Typography>
      <Box component="img" sx={{
        marginTop: '1vmin',
        borderRadius: '2vmin',
        maxBlockSize: '50vmin',
      }} src="./assets/images/custom_exchange_server.png" alt="Example of a message in the application">
      </Box>
      <Typography sx={{ marginTop: '3vmin' }}>Environment variables are substituted by the server automatically at the time of the request.</Typography>
      <Typography>To disable the use of a custom exchange rate server, clear the contents of the settings and click apply.</Typography>
      <Grid container sx={{ marginTop: '3vmin' }}>
        <List sx={{ marginRight: '3vmin' }} subheader={
          <div>
            <Typography sx={{ fontWeight: 'bold' }}>Variables: </Typography>
            <Divider></Divider>
          </div>
        }>
          <ListItemText primary={
            <div><b>{'{{ date }}'}</b></div>
          } secondary={
            'Date in YYYY-MM-dd format, for example 2023-01-01'
          } />
          <ListItemText primary={
            <div><b>{'{{ from }}'}</b></div>
          } secondary={
            'The code of the currency from which the conversion is made, example USD'
          } />
          <ListItemText primary={
            <div><b>{'{{ to }}'}</b></div>
          } secondary={
            'The code of the currency to which the conversion is made, example GEL'
          } />
        </List>
        <List subheader={
          <div>
            <Typography sx={{ fontWeight: 'bold' }}>Examples of settings (you can combine them): </Typography>
            <Divider></Divider>
          </div>
        }>
          <ListItemText primary={
            <div>{baseUrl}/<b>{'{{ date }}'}</b>/<b>{'{{ from }}'}</b>/<b>{'{{ to }}'}</b></div>
          } secondary={
            'Option with parameters in the path'
          } />
          <ListItemText primary={
            <div>{baseUrl}?date=<b>{'{{ date }}'}</b>&from=<b>{'{{ from }}'}</b>&to=<b>{'{{ to }}'}</b></div>
          } secondary={
            'Option with parameters in the params'
          } />
          <ListItemText primary={
            <div>{baseUrl.replace('https://', `https://USER:PASSWORD@`)}/<b>{'{{ date }}'}</b>/<b>{'{{ from }}'}</b>/<b>{'{{ to }}'}</b></div>
          } secondary={
            'Option with basic authentication'
          } />
          <ListItemText primary={
            <div>{baseUrl}/<b>{'{{ date }}'}</b>/<b>{'{{ from }}'}</b>/<b>{'{{ to }}'}?token=AUTH_TOKEN</b></div>
          } secondary={
            'Option with token authentication'
          } />
        </List>
      </Grid>
      <Grid container sx={{ marginTop: '3vmin' }}>
        <Grid item sm={1}>
          <Typography>Request:</Typography>
        </Grid>
        <Grid item sm={11}>
          <Typography sx={{ fontWeight: 'bold' }}>GET https://api.miniaccountant.app/v1/exchange_rate/2022-03-01/USD/GEL</Typography>
        </Grid>
        <Grid item sm={1}>
          <Typography>Response:</Typography>
        </Grid>
        <Grid item sm={11}>
          <Typography>Header <b>{'{ "Content-Type": "application/json" }'}</b></Typography>
          <Typography>Body <b>{'{ "rate": 2.5, "info": "Informational message" }'}</b></Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: '3vmin' }}>
        <Stack sx={{ marginRight: '1vmin' }}>
          <TextareaAutosize value={
            user?.customExchangeServer && !exchangeServerUrl.isChanged ?
              user.customExchangeServer : exchangeServerUrl.server
          } style={{
            width: '100vmin',
            fontWeight: 'bold',
          }} minRows={5} onChange={(event) => {
            setExchangeServerUrl({
              server: event.target.value,
              isChanged: true,
            });
          }}></TextareaAutosize>
        </Stack>
        <Stack>
          <Button variant="outlined" color="primary" sx={{ marginBottom: '1vmin' }} onClick={async () => {
            try {
              await updateDoc(userRef, { 'customExchangeServer': exchangeServerUrl.server.trim() });
              setShowMsg({
                type: 'success',
                message: `${(exchangeServerUrl.server.trim() !== '') ? 'Exchange Server was installed.' : 'Exchange Server was uninstalled.'}`,
                isShown: true,
              })
            } catch (err: any | Error) {
              setShowMsg({
                type: 'error',
                message: `${err?.message || 'The setting could not be applied. Try again later.'}`,
                isShown: true,
              })
            }
          }}>Apply</Button>
          <Button variant="outlined" color="primary" onClick={async () => {
            setExchangeServerUrl({
              server: user?.customExchangeServer || '',
              isChanged: false,
            })
          }}>Reset</Button>
        </Stack>
      </Grid>
      <div style={{ height: '10vmin' }}></div>
      <Snackbar open={showMsg.isShown} autoHideDuration={6000} onClose={() => {
        setShowMsg({ ...showMsg, isShown: false });
      }} sx={{ width: '100%', padding: '3vmin', justifyContent: 'end' }}>
        {
          <Alert onClose={() => {
            setShowMsg({ ...showMsg, isShown: false });
          }} sx={{ marginRight: '3vmin' }} severity={showMsg.type}>{showMsg.message}</Alert>
        }
      </Snackbar>
    </div>
  );
}

export default ExchangeServerRoute;