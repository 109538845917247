import moment, { Moment } from 'moment-timezone';
import { UserModel } from '../User';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  Timestamp,
  collection,
  doc,
  getDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';
import { randomStr } from '../../../utils/randomStr';

export const fullScope = ([
  'user', 'user_settings',
  'customers', 'exchange_rate', 'services',
  'payments_settings', 'invoices', 'expenses',
] as const).map((e) => e);

export type ScopeElement = typeof fullScope[0];

export class ApiTokenModel {
  constructor({
    id,
    requests = {},
    limit = 100,
    token,
    scope = [],
    lastRequest,
    user,
  }: {
    id?: string;
    requests?: { [key: string]: { [key: string]: number } };
    limit?: number;
    token?: string;
    scope: ScopeElement[];
    lastRequest?: Moment;
    user: DocumentReference<UserModel>;
  }) {
    this.id = typeof id === 'string' ? id : randomStr(20);
    this.requests = requests;
    this.limit = limit;
    this.token = typeof token === 'string' ? token : '';
    this.scope = scope.filter((e) => ApiTokenModel.checkScopeParam(e));
    this.lastRequest = lastRequest ? lastRequest : moment(0);
    this.user = user;
  }

  id: string;

  requests: { [key: string]: { [key: string]: number } };

  limit: number;

  token: string;

  lastRequest: Moment;

  scope: ScopeElement[];

  user: DocumentReference<UserModel>;

  static fromJson(id: string, json: { [key: string]: any }): ApiTokenModel {
    return new ApiTokenModel({
      id: id,
      requests: typeof json?.requests === 'object' ? json.requests : {},
      limit: typeof json?.limit === 'number' ? json.limit : 0,
      token: typeof json?.token === 'string' ? json.token : '',
      scope: Array.isArray(json?.scope) ?
        json.scope.filter((e) => ApiTokenModel.checkScopeParam(e)) : [],
      lastRequest: moment((json.lastRequest as Timestamp).toMillis()),
      user: (json.user as DocumentReference).withConverter<UserModel>({
        toFirestore: (doc: UserModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          UserModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      requests: this.requests,
      limit: this.limit,
      token: this.token,
      scope: this.scope,
      lastRequest: Timestamp.fromMillis(this.lastRequest.valueOf()),
      user: doc(FirebaseFirestore, this.user.path),
    };
  }

  static parent = collection(FirebaseFirestore,
    'apiTokens'
  ).withConverter<ApiTokenModel>({
    toFirestore: (doc: ApiTokenModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      ApiTokenModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<ApiTokenModel>> =>
    getDoc(doc(ApiTokenModel.parent, id));

  ref = (): DocumentReference<ApiTokenModel> => {
    if (typeof this.id === 'string')
      return doc(ApiTokenModel.parent, this.id);
    const docRef = doc(ApiTokenModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (
    transaction?: Transaction
  ): Promise<DocumentSnapshot<ApiTokenModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  static checkScopeParam = (param: string): boolean =>
    new RegExp(`^(${fullScope.join('|')})$`).test(param);

}
