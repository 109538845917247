import { Typography } from '@mui/material';
import './WebhooksRoute.css';

function WebhooksRoute() {
  return (
    <div className='WebhooksRoute'>
      <Typography align='center' variant='h6'>Coming soon...</Typography>
    </div>
  );
}

export default WebhooksRoute;