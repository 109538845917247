import { Currency } from '../Currency';
import { InvoiceTemplate } from '../InvoiceTemplate';
import { UserModel } from '../User';
import { PaymentSettingsModel } from '../PaymentSettings';
import { InvoiceModel } from '../Invoice';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  Query,
  collection,
  doc,
  getDoc,
  setDoc,
  where,
  query,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class CustomerModel {
  constructor({
    id,
    companyName = '',
    country = '',
    state = '',
    addressLine1 = '',
    addressLine2 = '',
    currency = new Currency({ name: 'usd' }),
    invoiceTemplate = InvoiceTemplate.def,
    paymentSettings,
    user,
  }: {
    id?: string;
    companyName?: string;
    country?: string;
    state?: string;
    addressLine1?: string;
    addressLine2?: string;
    currency?: Currency;
    invoiceTemplate?: InvoiceTemplate;
    paymentSettings?: DocumentReference<PaymentSettingsModel>;
    user: DocumentReference<UserModel>;
  }) {
    this.id = id;
    this.companyName = companyName;
    this.country = country;
    this.state = state;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.currency = currency;
    this.invoiceTemplate = invoiceTemplate;
    this.paymentSettings = paymentSettings
      ? paymentSettings
      : doc(PaymentSettingsModel.parent, user.id);
    this.user = user;
  }

  id?: string;

  companyName: string;

  country: string;

  state: string;

  addressLine1: string;

  addressLine2: string;

  currency: Currency;

  invoiceTemplate: InvoiceTemplate;

  paymentSettings: DocumentReference<PaymentSettingsModel>;

  user: DocumentReference<UserModel>;

  static fromJson(id: string, json: { [key: string]: any }): CustomerModel {
    return new CustomerModel({
      id: id,
      companyName: typeof json.companyName === 'string' ? json.companyName : '',
      country: typeof json.country === 'string' ? json.country : '',
      state: typeof json.state === 'string' ? json.state : '',
      addressLine1:
        typeof json.addressLine1 === 'string' ? json.addressLine1 : '',
      addressLine2:
        typeof json.addressLine2 === 'string' ? json.addressLine2 : '',
      currency:
        typeof json?.currency === 'string'
          ? Currency.fromString(json.currency)
          : new Currency({ name: 'usd' }),
      invoiceTemplate:
        typeof json?.invoiceTemplate === 'string'
          ? InvoiceTemplate.fromString(json.invoiceTemplate)
          : InvoiceTemplate.def,
      paymentSettings:
        json.paymentSettings instanceof DocumentReference
          ? (
            json.paymentSettings as DocumentReference
          ).withConverter<PaymentSettingsModel>({
            toFirestore: (doc: PaymentSettingsModel) => doc.toJson(),
            fromFirestore: (snapshot: QueryDocumentSnapshot) =>
              PaymentSettingsModel.fromJson(snapshot.id, snapshot.data()),
          })
          : doc(PaymentSettingsModel.parent,
            (json.user as DocumentReference).id
          ),
      user: (json.user as DocumentReference).withConverter<UserModel>({
        toFirestore: (doc: UserModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          UserModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      companyName: this.companyName,
      country: this.country,
      state: this.state,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      currency: this.currency.toString(),
      invoiceTemplate: this.invoiceTemplate.toString(),
      paymentSettings: doc(FirebaseFirestore, this.paymentSettings.path),
      user: doc(FirebaseFirestore, this.user.path),
    };
  }

  static parent = collection(FirebaseFirestore,
    'customers'
  ).withConverter<CustomerModel>({
    toFirestore: (doc: CustomerModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      CustomerModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<CustomerModel>> =>
    getDoc(doc(CustomerModel.parent, id));

  ref = (): DocumentReference<CustomerModel> => {
    if (typeof this.id === 'string')
      return doc(CustomerModel.parent, this.id);
    const docRef = doc(CustomerModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (
    transaction?: Transaction
  ): Promise<DocumentSnapshot<CustomerModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);

  child = {
    getInvoices: (): Query<InvoiceModel> =>
      query(InvoiceModel.parent, where('customer', '==', this.ref())),
  };
}
