const createInvoicesData = (
  userCurrency: string,
  userTaxFee: string,
  invoiceId: string,
  invoiceDate: string,
  invoiceNumber: string,
  invoiceAmount: string | null,
  customer: string | null,
  paidAmount: string | null,
  paidAmountInCurr: string | null,
) => ({
  userCurrency,
  userTaxFee,
  invoiceId,
  invoiceDate,
  invoiceNumber,
  invoiceAmount,
  customer,
  paidAmount,
  paidAmountInCurr,
});

const invoicesRows = [
  createInvoicesData('GEL', '1', 'KqKVhzyHHq2StCc9jbLf',
    '2022-01-31',
    '2',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    '1000.00',
    '2986.70',),
  createInvoicesData('GEL', '1', '2WUWx9RkulxrmMuPQCcb',
    '2022-02-28',
    '3',
    '1000.00',
    'JPMORGAN CHASE BANK N.A.',
    '1000.00',
    '3274.40',),
  createInvoicesData('GEL', '1',
    '8yYW320fAfIbkZEpvDau',
    '2022-03-31',
    '4',
    '2000.00',
    'JPMORGAN CHASE BANK N.A.',
    '2000.00',
    '6180.40',)
] as const;

const invoicesQuery = `
  SELECT
  * FROM
  \`invoicemaker-f5e1d.mini_accountant.invoices_slice_by_range\`(
    PARSE_TIMESTAMP("%Y%m%d",  "20220101", "Asia/Tbilisi"),
    PARSE_TIMESTAMP("%Y%m%d", "20231231", "Asia/Tbilisi"),
    "Asia/Tbilisi"
  );
  `;

export { invoicesRows, invoicesQuery };