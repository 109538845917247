const invoiceTemplates = (
  [
    'simple', 'simpleWithoutSign', 'modernGray',
    'modernBlue', 'modernGreen', 'modernRed',
  ] as const).map(
    (e) => e
  );
export type InvoiceTemplates = typeof invoiceTemplates[0];

export class InvoiceTemplate {
  constructor({ name }: { name: InvoiceTemplates }) {
    this.name = name;
  }

  name: InvoiceTemplates;

  static def: InvoiceTemplate = new InvoiceTemplate({
    name: 'simple',
  });

  static fromString(str: string): InvoiceTemplate {
    const lowerStr = str.replace(/[_-]/g, '').toLowerCase()
    const name = invoiceTemplates.find((e) => e.toLowerCase() === lowerStr);
    if (name) {
      return new InvoiceTemplate({ name });
    }
    throw new Error('Invalid type of the invoice template.');
  }

  static isValidString(str?: string): Boolean {
    const lowerStr = str?.replace(/[_-]/g, '')?.toLowerCase()
    const name = invoiceTemplates.find((e) => e.toLowerCase() === lowerStr);
    return !!name;
  }

  toString(): string {
    return this.name;
  }
}
