import moment, { Moment } from 'moment-timezone';
import { Currency } from '../Currency';
import { ServiceModel } from '../Service';
import { CustomerModel } from '../Customer';
import { UserModel } from '../User';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  Timestamp,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class InvoicePositionModel {
  constructor({
    data,
    service,
  }: {
    data: ServiceModel;
    service: DocumentReference<ServiceModel>;
  }) {
    this.data = data;
    this.service = service;
  }

  data: ServiceModel;

  service: DocumentReference<ServiceModel>;

  static fromJson(json: { [key: string]: any }): InvoicePositionModel {
    return new InvoicePositionModel({
      data: ServiceModel.fromJson(
        (json.service as DocumentReference).id,
        json.data as { [key: string]: any }
      ),
      service: (json.service as DocumentReference).withConverter<ServiceModel>({
        toFirestore: (doc: ServiceModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          ServiceModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      data: this.data.toJson(),
      service: doc(FirebaseFirestore, this.service.path),
    };
  }
}

export class InvoiceTransactionModel {
  constructor({
    date,
    sum = 0,
    exchangeRate = 1,
  }: {
    date: Moment;
    sum?: number;
    exchangeRate?: number;
  }) {
    this.date = date;
    this.sum = sum;
    this.exchangeRate = exchangeRate;
  }

  date: Moment;

  sum: number;

  exchangeRate: number;

  static fromJson(json: { [key: string]: any }): InvoiceTransactionModel {
    return new InvoiceTransactionModel({
      date: moment((json.date as Timestamp).toMillis()),
      sum: typeof json.sum === 'number' ? json.sum : 0,
      exchangeRate:
        typeof json.exchangeRate === 'number' ? json.exchangeRate : 1,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      date: Timestamp.fromMillis(this.date.valueOf()),
      sum: this.sum,
      exchangeRate: this.exchangeRate,
    };
  }
}

export class InvoiceModel {
  constructor({
    id,
    number,
    date,
    currency = new Currency({ name: 'usd' }),
    positions = [],
    transactions = [],
    customer,
    user,
  }: {
    id?: string;
    number: number;
    date: Moment;
    currency?: Currency;
    positions?: InvoicePositionModel[];
    transactions?: InvoiceTransactionModel[];
    customer: DocumentReference<CustomerModel>;
    user: DocumentReference<UserModel>;
  }) {
    this.id = id;
    this.number = number;
    this.date = date;
    this.currency = currency;
    this.positions = positions;
    this.transactions = transactions;
    this.customer = customer;
    this.user = user;
  }

  id?: string;

  number: number;

  date: Moment;

  currency: Currency;

  positions: InvoicePositionModel[];

  transactions: InvoiceTransactionModel[];

  customer: DocumentReference<CustomerModel>;

  user: DocumentReference<UserModel>;

  static fromJson(id: string, json: { [key: string]: any }): InvoiceModel {
    return new InvoiceModel({
      id: id,
      number: json.number as number,
      date: moment((json.date as Timestamp).toMillis()),
      currency:
        Currency.isValidString(json.currency)
          ? Currency.fromString(json.currency)
          : new Currency({ name: 'usd' }),
      positions: (json.positions as { [key: string]: any }[]).map((e) =>
        InvoicePositionModel.fromJson(e)
      ),
      transactions: (json.transactions as { [key: string]: any }[]).map((e) =>
        InvoiceTransactionModel.fromJson(e)
      ),
      customer: (
        json.customer as DocumentReference
      ).withConverter<CustomerModel>({
        toFirestore: (doc: CustomerModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          CustomerModel.fromJson(snapshot.id, snapshot.data()),
      }),
      user: (json.user as DocumentReference).withConverter<UserModel>({
        toFirestore: (doc: UserModel) => doc.toJson(),
        fromFirestore: (snapshot: QueryDocumentSnapshot) =>
          UserModel.fromJson(snapshot.id, snapshot.data()),
      }),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      number: this.number,
      date: Timestamp.fromMillis(this.date.valueOf()),
      currency: this.currency.toString(),
      positions: this.positions.map((e) => e.toJson()),
      transactions: this.transactions.map((e) => e.toJson()),
      customer: doc(FirebaseFirestore, this.customer.path),
      user: doc(FirebaseFirestore, this.user.path),
    };
  }

  static parent = collection(FirebaseFirestore, 'invoices').withConverter<InvoiceModel>({
    toFirestore: (doc: InvoiceModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      InvoiceModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<InvoiceModel>> =>
    getDoc(doc(InvoiceModel.parent, id));

  ref = (): DocumentReference<InvoiceModel> => {
    if (typeof this.id === 'string')
      return doc(InvoiceModel.parent, this.id);
    const docRef = doc(InvoiceModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<InvoiceModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
